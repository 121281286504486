<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <template v-if="loading">
            <v-col v-for="i in 10" :key="i" cols="12" md="4">
              <v-skeleton-loader
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </template>
          <template v-if="!loading && searchServiceList.length">
            <v-col cols="12" md="12">
              <v-expansion-panels v-model="integrationToogle">
                <template v-for="(item, index) in searchServiceList">
                  <v-expansion-panel
                    :id="item.title"
                    :key="index"
                    class="cursor-pointer"
                  >
                    <v-expansion-panel-header class="py-2">
                      <v-row align="center">
                        <v-col cols="auto">
                          <v-img
                            v-if="item.img"
                            :src="item.img"
                            class="mb-2"
                            contain
                            height="40"
                            width="40"
                          ></v-img>
                          <v-icon
                            v-else
                            class="mb-1"
                            color="primary"
                            size="40"
                            v-text="item.icon"
                          ></v-icon>
                        </v-col>
                        <v-col>
                          <div>
                            <div class="mx-1 mb-2 darkGrey--text font-size-h3">
                              {{ item.title }}
                            </div>
                            <div class="mx-1 smallFont">
                              <!-- {{ item.description }} -->
                            </div>
                          </div>
                        </v-col>
                        <v-chip
                          v-if="item?.label"
                          :color="item?.label?.color"
                          class="top float-right mr-4"
                          small
                          @click="openUpdatedLink(item?.label?.link)"
                        >
                          {{ item?.label?.label }}
                        </v-chip>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        v-if="
                          [
                            'github-action',
                            'fastlane',
                            'jenkins',
                            'circle-cli',
                            'github-ci-cd',
                            'travis-cli',
                            'azure-pipeline',
                            'bitrise-ci',
                            'x-codde-cloud',
                            'codemagic',
                            'bitrise-ci',
                          ].includes(item.value)
                        "
                      >
                        <v-col cols="12">
                          Use the following credentials to connect this app.
                        </v-col>
                        <v-col cols="12">
                          App ID:
                          <template v-if="$route.params.app_id">
                            <v-chip
                              class="black--text cursor-pointer"
                              color="grey"
                              label
                            >
                              {{ $route.params.app_id }}
                            </v-chip>
                            <v-btn icon @click="copy($route.params.app_id)">
                              <v-icon v-text="'mdi-content-copy'"></v-icon>
                            </v-btn>
                          </template>
                          <v-btn
                            v-else
                            color="primary"
                            class="text-transform-none ml-2"
                            :outlined="$vuetify.theme.dark"
                            @click="
                              $router.push({
                                name: 'apps',
                                query: {
                                  to: 'app-integrations',
                                  tab: 'releases',
                                },
                              })
                            "
                          >
                            Select ID
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          API Token:
                          <v-chip
                            v-if="selectedToken"
                            class="black--text cursor-pointer"
                            color="grey"
                            label
                            @click="getToken"
                          >
                            <MaxText :text="selectedToken" max="15"></MaxText>
                          </v-chip>
                          <span
                            v-else
                            class="semi-bold cursor-pointer primary--text text-decoration-underline bodyFont"
                            @click="getToken"
                          >
                            Select a token
                          </span>
                          <v-btn
                            v-if="selectedToken"
                            :disabled="!selectedToken"
                            icon
                            @click="copy(selectedToken)"
                          >
                            <v-icon v-text="'mdi-content-copy'"></v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            class="text-transform-none"
                            color="primary"
                            :outlined="$vuetify.theme.dark"
                            @click="openDocs(item.linkName)"
                          >
                            <v-icon left> mdi-message</v-icon>
                            Documentation
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.value === 'expo-eas' && item?.detail">
                        <v-col
                          v-for="(detail, dindex) in item?.detail"
                          :key="dindex"
                          cols="12"
                          md="6"
                        >
                          <v-row>
                            <v-col class="pt-5" cols="12">
                              <v-row align="center">
                                <v-col cols="auto">
                                  <v-img
                                    v-if="detail.img"
                                    :src="detail.img"
                                    class="mb-2"
                                    contain
                                    height="40"
                                    width="40"
                                  ></v-img>
                                  <v-icon
                                    v-else
                                    class="mb-1"
                                    color="primary"
                                    size="40"
                                    v-text="detail.icon"
                                  ></v-icon>
                                </v-col>
                                <v-col cols="auto">
                                  <div>
                                    <div>
                                      {{ detail.name }}
                                    </div>
                                    <div>
                                      {{ detail.description }}
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12">
                              App ID:
                              <template v-if="$route.params.app_id">
                                <v-chip
                                  class="black--text cursor-pointer"
                                  color="grey"
                                  label
                                >
                                  {{ $route.params.app_id }}
                                </v-chip>
                                <v-btn icon @click="copy($route.params.app_id)">
                                  <v-icon v-text="'mdi-content-copy'"></v-icon>
                                </v-btn>
                              </template>
                              <v-btn
                                v-else
                                color="primary"
                                class="text-transform-none ml-2"
                                :outlined="$vuetify.theme.dark"
                                @click="
                                  $router.push({
                                    name: 'apps',
                                    query: {
                                      to: 'app-integrations',
                                      tab: 'releases',
                                    },
                                  })
                                "
                              >
                                Select ID
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              API Token:
                              <v-chip
                                v-if="selectedToken"
                                class="black--text cursor-pointer"
                                color="grey"
                                label
                                @click="getToken"
                              >
                                <MaxText
                                  :text="selectedToken"
                                  max="15"
                                ></MaxText>
                              </v-chip>
                              <span
                                v-else
                                class="semi-bold cursor-pointer primary--text text-decoration-underline bodyFont"
                                @click="getToken"
                              >
                                Select a token
                              </span>
                              <v-btn
                                v-if="selectedToken"
                                :disabled="!selectedToken"
                                icon
                                @click="copy(selectedToken)"
                              >
                                <v-icon v-text="'mdi-content-copy'"></v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <v-btn
                                class="text-transform-none"
                                color="primary"
                                :outlined="$vuetify.theme.dark"
                                @click="openDocs(detail.linkName)"
                              >
                                <v-icon left> mdi-message</v-icon>
                                Documentation
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.value == 'ta-cli'">
                        <v-col cols="12">
                          <div class="my-2 bodyFont">Step 1 - Install</div>
                          <div>
                            <div class="mt-4">macOS & Linux</div>
                            <div>
                              <div>
                                <v-chip
                                  @click="
                                    copy(`curl -Ls
                                        https://github.com/testappio/cli/releases/latest/download/install
                                        | bash`)
                                  "
                                >
                                  curl -Ls
                                  https://github.com/testappio/cli/releases/latest/download/install
                                  | bash
                                </v-chip>
                              </div>
                            </div>
                            <div class="mt-4 mb-2">
                              <div class="mb-2">For windows:</div>
                              <v-btn
                                class="text-transform-none"
                                color="primary"
                                :outlined="$vuetify.theme.dark"
                                target="_blank"
                                href="https://help.testapp.io/ta-cli/#windows"
                              >
                                <v-icon left> mdi-message</v-icon>
                                Documentation
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div class="my-2 bodyFont">Step 2 - Configure</div>
                          <div class="">
                            <div class="my-2">
                              Select a
                              <span
                                class="cursor-pointer primary--text"
                                @click="getToken"
                                >token</span
                              >
                            </div>
                            <v-chip @click="getToken">
                              ta-cli config --api_token=
                              <span class="mr-1">
                                <MaxText
                                  v-if="selectedToken"
                                  :text="selectedToken"
                                  max="15"
                                ></MaxText>
                                <span v-else>get token</span>
                              </span>
                              --app_id={{ $route.params.app_id }}
                            </v-chip>
                            <v-btn
                              :disabled="!selectedToken"
                              icon
                              @click="
                                copy(
                                  `ta-cli config --api_token=${selectedToken} --app_id=${$route.params.app_id}`
                                )
                              "
                            >
                              <v-icon v-text="'mdi-content-copy'"></v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div>
                            <v-btn
                              class="text-transform-none"
                              color="primary"
                              :outlined="$vuetify.theme.dark"
                              href="https://help.testapp.io/ta-cli/"
                              target="_blank"
                            >
                              <v-icon left> mdi-message</v-icon>
                              Documentation
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-col>
            <v-col class="pb-0 darkGrey--text" cols="12">
              <div class="mb-8">
                <v-icon color="grey" left small>mdi-help-circle</v-icon>
                <span>We missed an integration that you need?</span>
                <span
                  class="ml-1 primary--text text-decoration-underline cursor-pointer"
                  @click="openIntercomChat(contactMessage)"
                  >Let us know!</span
                >
              </div>
            </v-col>
          </template>
          <template
            v-if="!loading && !searchServiceList.length && canManageTeam"
          >
            <v-col cols="12">
              <no-data :first-text="`No matching record found`"></no-data>
            </v-col>
          </template>
        </v-row>
      </v-col>

      <Modal
        v-model="openSelectTokenModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="900"
        @close="openSelectTokenModal = false"
      >
        <template #card>
          <api-credentials
            v-if="openSelectTokenModal"
            from-app-page
            @close="openSelectTokenModal = false"
            @select="successSelectionToken"
          ></api-credentials>
        </template>
      </Modal>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ApiCredentials from "@/view/pages/user/settings/api-tokens";
import { releaseIntegrationList } from "@/consts/releaseIntegrationList";

export default {
  components: {
    ApiCredentials,
  },
  data() {
    return {
      busy: false,
      searchText: "",
      integrationToogle: null,
      loading: false,
      slackEnabled: false,
      teamEnabled: false,
      slackStatus: false,
      slackPopupWindow: "",
      confirmationModal: false,
      slackInfoPopup: false,
      showUpgradeInfo: false,
      selectedIntegration: {},
      editTeamModal: true,
      contactMessage: {
        message:
          "Hello, I would like to suggest a missing integration for Distribution:",
        subject: "Suggesting an integration for Distribution",
      },
      teamStatus: false,
      selectedToken: "",
      openSelectTokenModal: false,
    };
  },
  computed: {
    serviceList() {
      return releaseIntegrationList;
    },
    searchServiceList() {
      return this.serviceList.filter((label) =>
        label.title.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.toogle == "ta-cli") {
      this.integrationToogle = 0;
    }
    if (this.$route.query && this.$route.query.toogle == "fastlane-plugin") {
      this.integrationToogle = 2;
    }
  },
  methods: {
    ...mapMutations({
      setFeedbackModalDetail: "setFeedbackModalDetail",
      setTokenList: "setTokenList",
    }),
    successSelectionToken(token) {
      this.selectedToken = token;
      this.openSelectTokenModal = false;
    },
    openDocs(link) {
      window.open(link, "_blank");
    },
    getToken() {
      if (this.$route.params.app_id) {
        this.setTokenList([]);
        this.openSelectTokenModal = true;
      } else {
        this.$router.push({
          name: "apps",
          query: { to: "app-integrations", tab: "releases" },
        });
      }
    },
    openFeedbackPopup() {
      this.setFeedbackModalDetail(true);
    },
    openUpdatedLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
