<template>
  <v-card outlined>
    <v-card-title>
      <div>
        <div class="bold-text">API Tokens</div>
        <div v-if="fromAppPage" class="text-body-1">
          Select or create a new one
        </div>
        <div v-if="!fromAppPage" class="text-body-1">
          These tokens allow you to deploy using our CLI or other automated
          tools
        </div>
        <div v-if="!fromAppPage" class="mt-6 text-body-1">
          <div>
            <span>
              Check
              <a
                class="text-decoration-underline"
                href="https://help.testapp.io/topic/integrations/"
                target="_blank"
                >integrations</a
              >
              for more information.</span
            >
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!getInactiveUserMessage && canManageRelease"
        id="createNewApiTokenBtn"
        :outlined="$vuetify.theme.dark"
        color="primary"
        @click="(showCreateForm = true), resetForm()"
      >
        Create API Token
      </v-btn>
      <v-btn v-if="fromAppPage" class="mx-2" icon @click="$emit('close')">
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="getTokenList"
                :loading="busy"
                class="v-card v-sheet v-sheet--outlined"
                hide-default-footer
                mobile-breakpoint="100"
              >
                <template id="tokenName" #item.name="{ item }">
                  <div>{{ item.name }}</div>
                </template>
                <template #item.team_name="{ item }">
                  <span class="teamName">
                    {{ item.team ? item.team.name : "" }}
                  </span>
                </template>
                <template #item.created_by="{ item }">
                  <user-avatar :user-detail="item.user"></user-avatar>
                </template>
                <template #item.token="{ item }">
                  <v-chip
                    class="black--text cursor-pointer"
                    color="grey"
                    label
                    @click="selectToken(item.token)"
                  >
                    <MaxText :text="item.token" max="15"></MaxText>
                  </v-chip>
                </template>
                <template #item.created="{ item }" class="pr-0">
                  <vue-hoverable-date
                    :date="item.created_at"
                  ></vue-hoverable-date>
                </template>
                <template #item.last_used="{ item }" class="pr-0">
                  <vue-hoverable-date
                    v-if="item.last_used"
                    :date="item.last_used"
                  ></vue-hoverable-date>
                  <span v-else>Never</span>
                </template>
                <template #item.status="{ item }">
                  <span
                    :class="item.status === 1 ? 'success--text' : 'error--text'"
                    >{{ item.status === 1 ? "Active" : "Inactive" }}</span
                  >
                </template>
                <template #item.action="{ item }">
                  <ActionDropdown
                    v-if="!getInactiveUserMessage && canManageRelease"
                  >
                    <template v-slot:list>
                      <!-- item in the drop down -->
                      <v-list-item
                        id="renameNameOption"
                        :disabled="item.status !== 1"
                        @click.stop="openRenameKeyModal(item)"
                      >
                        <v-icon small>edit</v-icon>
                        Rename Key
                      </v-list-item>
                      <v-list-item
                        id="removeTokenOption"
                        @click="removeToken(item.id, item.name)"
                      >
                        <v-icon small>mdi-delete</v-icon>
                        Delete Key
                      </v-list-item>
                    </template>
                  </ActionDropdown>
                </template>
                <template #loading>
                  <v-skeleton-loader
                    type="table-heading, list-item-two-line"
                  ></v-skeleton-loader>
                </template>
                <template #no-data>
                  <no-data
                    :first-text="'No data available'"
                    second-text="These credentials are required to authenticate with the API. "
                  ></no-data>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <Modal
      v-model="removeTokenModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 650 : ''"
      no-py
    >
      <template #card>
        <v-card>
          <v-card-title class="font-weight-bold">
            Delete API Token</v-card-title
          >
          <v-divider />

          <v-card-text class="message">
            <v-alert class="warning--text" color="warning" outlined>
              Heads up! You're about to delete
              <b id="selectedName">{{ selectedName }}</b> API Token which may
              used in TA-CLI or Github Action or similar integrations!
            </v-alert>
            <div>
              Deleting these credentials will break any integrations that use
              them. Please be sure to update those integrations before deleting.
              This action <b>cannot</b> be undone.
            </div>
            <div class="my-3">
              If you're sure, please type in the exact name of the credentials
              to confirm.
            </div>
            <div>
              <v-text-field
                id="confirmDeleteField"
                v-model="confirmRemoveName"
                filled
                hint="This field is case sensitive."
                placeholder="Name"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="text-transform-none"
              color="primary"
              text
              @click="onCancelRemoveModal"
            >
              Cancel
            </v-btn>
            <v-btn
              id="permanentDeleteToken"
              :disabled="selectedName !== confirmRemoveName"
              :loading="loading"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="error"
              @click="confirmRemove"
              >Permanently delete this credential
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal
      v-model="showCreateForm"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="showCreateForm = false"
    >
      <template #card>
        <v-card v-if="showCreateForm" class="pa-3">
          <v-card-title> Create API Token</v-card-title>
          <v-card-text>
            <v-row id="createNewTokenForm">
              <v-col cols="12">
                <v-text-field
                  id="createTokenName"
                  v-model="formValues.name"
                  v-validate="'required|min:3|max:27'"
                  counter="27"
                  dense
                  filled
                  label="Name"
                  placeholder="Where you are going to use this token?"
                  v-bind="veeValidate('Name', '')"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="!fromAppPage" class="py-0" cols="12">
                <span class="mr-3 font14"> Select team </span>
                <v-chip
                  :color="$vuetify.theme.dark ? 'primary' : ''"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  label
                  @click="openSelectTeamModal"
                >
                  {{ currentTeamName || getTeamName }}
                </v-chip>
              </v-col>
              <v-col class="py-0 text-right" cols="12">
                <v-btn
                  id="createTokenCancel"
                  class="mx-2 text-transform-none"
                  color="primary"
                  text
                  @click="cancelCreateForm"
                  >Cancel
                </v-btn>
                <v-btn
                  id="createTokenSubmit"
                  :disabled="errors.any()"
                  :loading="loading"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="createAPIToken"
                  >Create
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal
      v-model="renameKeyModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @click="renameKeyModal = false"
    >
      <template #card>
        <v-card v-if="renameKeyModal" id="renameField" class="pa-3" flat tile>
          <v-card-title> Rename key</v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="12">
                <v-text-field
                  id="changeNameField"
                  v-model="changeText"
                  v-validate="'required|max:27'"
                  filled
                  placeholder="Name"
                  v-bind="veeValidate('Name', '')"
                >
                </v-text-field>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  :disabled="loading"
                  class="mx-3 text-transform-none"
                  color="primary"
                  text
                  @click.stop="renameKeyModal = false"
                  >Cancel
                </v-btn>
                <v-btn
                  id="changeNameBtn"
                  :loading="loading"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="changeName"
                  >Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal
      v-model="teamListMenu"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="teamListMenu = false"
    >
      <template #card>
        <team-list
          from-api-page
          @close="teamListMenu = false"
          @selected="selectTeam"
        ></team-list>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import {
  CREATE_API_TOKEN,
  DELETE_API_TOKEN,
  GET_API_TOKEN_LIST,
  UPDATE_API_TOKEN,
} from "@/store/users/settings.module.js";
import veeValidate from "@/mixins/veeValidate.js";
import { currentTimestamp, get } from "@/core/services/helper.service";
import TeamList from "@/view/components/Team/List.vue";
import { GET_TEAMS } from "@/store/team/manageTeam.module.js";
import { mapGetters, mapMutations } from "vuex";
import UserAvatar from "@/view/components/Common/UserAvatar";

export default {
  name: "api-tokens",
  mixins: [veeValidate],
  components: { TeamList, UserAvatar },
  props: {
    fromAppPage: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo: {
    title: "TestApp.io - Portal - API Tokens",
    meta: [
      {
        name: "description",
        content: `A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!`,
      },
    ],
  },
  data() {
    return {
      get,
      showCreateForm: false,
      selectedName: "",
      teamMenu: false,
      renameKeyModal: false,
      showRenameField: false,
      currentTeamName: "",
      selectedId: "",
      expanded: [],
      changeText: "",
      confirmRemoveName: "",
      busy: false,
      removeTokenModal: false,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          width: "15%",
          value: "name",
        },
        {
          text: "Team Name",
          align: "left",
          sortable: false,
          width: "15%",
          value: "team_name",
        },
        {
          text: "User",
          align: "left",
          sortable: false,
          width: "15%",
          value: "created_by",
        },
        {
          text: "API Token",
          align: "left",
          sortable: false,
          width: "15%",
          value: "token",
        },
        {
          text: "Created",
          align: "left",
          sortable: false,
          width: "17%",
          value: "created",
        },
        {
          text: "Last Used",
          align: "left",
          sortable: false,
          width: "17%",
          value: "last_used",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          width: "3%",
          value: "status",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          width: "3%",
          value: "action",
        },
      ],
      formValues: {
        name: "",
        team_id: "",
      },
      teamList: [],
      teamListMenu: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getTokenList: "getTokenList",
      getTeamId: "getTeamId",
      getTeamName: "getTeamName",
      getInactiveUserMessage: "getInactiveUserMessage",
      getTeamList: "getTeamList",
      getTotalTeamList: "getTotalTeamList",
      getTotalTeamCount: "getTotalTeamCount",
    }),
    totalLength() {
      return Math.ceil(this.getTotalTeamCount / 4);
    },
  },
  created() {
    this.formValues.team_id = this.getTeamId;
    this.currentTeamName = this.getTeamName;
    if (this.fromAppPage) {
      this.$options.metaInfo = {};
      this.headers = this.headers.filter(
        (x) => !["team_name", "created_by"].includes(x.value)
      );
    }
    this.getTokenLists();
    this.getTeamsList();
  },
  methods: {
    ...mapMutations({
      updateTokenList: "updateTokenList",
      removeTokenList: "removeTokenList",
      addNewToken: "addNewToken",
    }),
    selectToken(token) {
      if (this.fromAppPage) {
        this.$emit("select", token);
      } else {
        this.copy(token);
      }
    },
    selectTeam(team) {
      this.formValues.team_id = team.id;
      this.currentTeamName = team.name;
      this.teamListMenu = false;
    },
    openSelectTeamModal() {
      this.teamListMenu = true;
    },
    getTokenLists() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GET_API_TOKEN_LIST, { current_team: this.fromAppPage })
        .then(() => {
          if (this.$vuetify.theme.dark) {
            this.$vuetify.theme.dark = true;
          }

          this.busy = false;
        })
        .catch(() => {
          this.busy = false;
        });
    },
    resetForm() {
      this.formValues = {
        name: "",
        team_id: this.getTeamId,
      };
      this.currentTeamName = this.getTeamName;
      this.$validator.errors.clear();
    },
    async createAPIToken() {
      if (await this.validateAllFields()) {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(CREATE_API_TOKEN, {
            name: this.formValues.name,
            team_id: this.formValues.team_id || this.getTeamId,
          })
          .then(({ data }) => {
            this.showCreateForm = false;
            let dataToAdd = {
              id: data.data.id,
              name: this.formValues.name,
              token: data.data.token,
              last_used: 0,
              team: {
                name: this.currentTeamName,
                id: this.formValues.team_id || this.getTeamId,
              },
              created_at: currentTimestamp(),
              status: 1,
            };
            this.$nextTick(() => {
              this.addNewToken(dataToAdd);
            });
            this.$emit("select", dataToAdd.token);
            this.loading = false;
            this.notifyUserMessage({ message: data.data.message });
            this.getTokenLists();
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    getTeamsList() {
      this.$store
        .dispatch(GET_TEAMS)
        .then((response) => {
          this.teamList = response.data[0].data;
        })
        .catch(() => {});
    },
    editName(id) {
      this.selectedId = id;
      this.showRenameField = true;
    },
    onCancelRemoveModal() {
      this.removeTokenModal = false;
      this.confirmRemoveName = "";
      this.selectedName = "";
      this.selectedId = "";
    },
    cancelCreateForm() {
      this.showCreateForm = false;
      this.resetForm();
    },
    openRenameKeyModal(item) {
      this.selectedId = item.id;
      this.changeText = item.name;
      this.renameKeyModal = true;
    },
    changeName() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(UPDATE_API_TOKEN, {
          name: this.changeText,
          id: this.selectedId,
        })
        .then(({ data }) => {
          this.loading = false;
          this.notifyUserMessage({ message: data.data.message });
          this.confirmRemoveName = "";
          this.renameKeyModal = false;
          let dataToUpdate = {
            name: this.changeText,
            id: this.selectedId,
          };
          this.updateTokenList(dataToUpdate);
          this.showRenameField = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    confirmRemove() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_API_TOKEN, this.selectedId)
        .then(({ data }) => {
          this.removeTokenList(this.selectedId);
          this.removeTokenModal = false;
          this.confirmRemoveName = "";
          this.loading = false;
          this.getTokenLists();
          this.notifyUserMessage({ message: data.data.message });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item.id === this.expanded[0].id ? [] : [item];
      }
    },
    removeToken(id, name) {
      this.selectedId = id;
      this.changeText = "";
      this.selectedName = name;
      this.removeTokenModal = true;
    },
    async copy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.notifyUserMessage({
          message: "Copied successfully",
          timeout: true,
        });
      } catch (error) {
        this.notifyErrorMessage({
          message: "An error occurred while copying",
          timeout: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.warning--text {
  color: #8c6e00 !important;
}
</style>
