export const releaseIntegrationList = [
  {
    title: "TA-CLI",
    value: "ta-cli",
    description:
      "TestApp.io CLI to automate your releases with a single command line for any app project and both Android & iOS",
    img: "/media/svg/testappio_cli.svg",
    linkName: "https://help.testapp.io/ta-cli/",
    status: 1,
  },
  {
    title: "Fastlane",
    value: "fastlane",
    img: "/media/svg/fastlane.svg",
    color: "red darken-5",
    status: 0,
    linkName: "https://help.testapp.io/fastlane-plugin/",
    description:
      "Connect to fastlane which is the easiest way to automate building and releasing your iOS and Android apps",
  },
  {
    title: "Xcode Cloud",
    value: "x-codde-cloud",
    img: "/media/integrations/x-code.png",
    color: "red darken-5",
    status: 0,
    label: { label: "New", color: "success" },
    linkName: "https://help.testapp.io/xcode-cloud/",
    description: "Upload the IPA to TestApp.io from Xcode Cloud",
  },
  {
    title: "Expo EAS",
    value: "expo-eas",
    img: "/media/logos/expo-icon.svg",
    color: "red darken-5",
    detail: [
      {
        name: "Github Actions",
        icon: "mdi-git",
        description: "Build and upload",
        linkName: "https://help.testapp.io/github-actions-expo-eas/",
      },
      {
        name: "Circle CI",
        img: "/media/logos/circleci.png",
        description: "Build and upload",
        linkName: "https://help.testapp.io/circle-ci-expo-eas/",
      },
    ],
    status: 0,
    linkName: "https://help.testapp.io/circle-ci-expo-eas/",
    label: { label: "New", color: "success" },
    description:
      "Connect Expo EAS with CircleCI and Gitlab which is the easiest way to automate building and releasing your iOS and Android apps",
  },
  {
    title: "Codemagic CI/CD",
    value: "codemagic",
    img: "/media/logos/codemagic-icon.svg",
    color: "red darken-5",
    status: 0,
    linkName: "https://help.testapp.io/codemagic/",
    label: { label: "New", color: "success" },
    description:
      "Connect to Codemagic CI/CD which is the easiest way to automate building and releasing your iOS and Android apps",
  },
  {
    title: "Github Actions",
    value: "github-action",
    icon: "mdi-git",
    color: "red darken-5",
    status: 0,
    labels: { label: "Available", color: "success" },
    linkName: "https://help.testapp.io/github-action/",
    description:
      "Use our workflow to upload both your Android (APK) & iOS (IPA) to our server and notify your team members",
  },
  {
    title: "Gitlab CI/CD",
    value: "github-ci-cd",
    icon: "mdi-gitlab",
    color: "red darken-5",
    status: 0,
    labels: { label: "Available", color: "success" },
    linkName: "https://help.testapp.io/gitlab-ci-cd/",
    description:
      "Connect to Gitlab CI/CD which is the easiest way to automate building and releasing your iOS and Android apps",
  },
  {
    title: "Circle CI",
    value: "circle-cli",
    img: "/media/logos/circleci.png",
    color: "red darken-5",
    status: 0,
    linkName: "https://help.testapp.io/circle-ci/",
    labels: { label: "Available", color: "success" },
    description:
      "Connect to Circle CI which is the easiest way to automate building and releasing your iOS and Android apps",
  },
  {
    title: "Jenkins",
    value: "jenkins",
    img: "/media/logos/jenkins.png",
    color: "red darken-5",
    status: 0,
    linkName: "https://help.testapp.io/jenkins/",
    labels: { label: "Available", color: "success" },
    description:
      "Connect to Jenkins which is the easiest way to automate building and releasing your iOS and Android apps",
  },
  {
    title: "Bitrise CI ",
    value: "bitrise-ci",
    img: "/media/svg/bitriseio-icon.svg",
    color: "red darken-5",
    status: 0,
    linkName: "https://help.testapp.io/bitrise",
    description:
      "Bitrise CI pipeline allows you to build and upload both Android & iOS apps to TestApp.io to notify your testers for testing and feedback.",
  },
  {
    title: "Azure Pipeline ",
    value: "azure-pipeline",
    img: "/media/svg/microsoft_azure-icon.svg",
    color: "red darken-5",
    status: 0,
    linkName: "https://help.testapp.io/azure-pipeline/",
    description:
      "Azure Pipeline allows you to build and upload both Android and iOS apps to TestApp.io for testing and feedback.",
  },
  {
    title: "Travis CI",
    value: "travis-cli",
    img: "/media/svg/travis_ci.svg",
    color: "red darken-5",
    labels: { label: "Available", color: "success" },
    status: 0,
    linkName: "https://help.testapp.io/travis-ci/",
    description:
      "Connect to Travis CI which is the easiest way to automate building and releasing your iOS and Android apps",
  },
];
