var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm.loading ? _vm._l(10, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "max-width": "300",
        "type": "card"
      }
    })], 1);
  }) : _vm._e(), !_vm.loading && _vm.searchServiceList.length ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-expansion-panels', {
    model: {
      value: _vm.integrationToogle,
      callback: function callback($$v) {
        _vm.integrationToogle = $$v;
      },
      expression: "integrationToogle"
    }
  }, [_vm._l(_vm.searchServiceList, function (item, index) {
    var _item$label, _item$label3;
    return [_c('v-expansion-panel', {
      key: index,
      staticClass: "cursor-pointer",
      attrs: {
        "id": item.title
      }
    }, [_c('v-expansion-panel-header', {
      staticClass: "py-2"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [item.img ? _c('v-img', {
      staticClass: "mb-2",
      attrs: {
        "src": item.img,
        "contain": "",
        "height": "40",
        "width": "40"
      }
    }) : _c('v-icon', {
      staticClass: "mb-1",
      attrs: {
        "color": "primary",
        "size": "40"
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-col', [_c('div', [_c('div', {
      staticClass: "mx-1 mb-2 darkGrey--text font-size-h3"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "mx-1 smallFont"
    })])]), item !== null && item !== void 0 && item.label ? _c('v-chip', {
      staticClass: "top float-right mr-4",
      attrs: {
        "color": item === null || item === void 0 ? void 0 : (_item$label = item.label) === null || _item$label === void 0 ? void 0 : _item$label.color,
        "small": ""
      },
      on: {
        "click": function click($event) {
          var _item$label2;
          return _vm.openUpdatedLink(item === null || item === void 0 ? void 0 : (_item$label2 = item.label) === null || _item$label2 === void 0 ? void 0 : _item$label2.link);
        }
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$label3 = item.label) === null || _item$label3 === void 0 ? void 0 : _item$label3.label) + " ")]) : _vm._e()], 1)], 1), _c('v-expansion-panel-content', [['github-action', 'fastlane', 'jenkins', 'circle-cli', 'github-ci-cd', 'travis-cli', 'azure-pipeline', 'bitrise-ci', 'x-codde-cloud', 'codemagic', 'bitrise-ci'].includes(item.value) ? _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" Use the following credentials to connect this app. ")]), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" App ID: "), _vm.$route.params.app_id ? [_c('v-chip', {
      staticClass: "black--text cursor-pointer",
      attrs: {
        "color": "grey",
        "label": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$route.params.app_id) + " ")]), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.copy(_vm.$route.params.app_id);
        }
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s('mdi-content-copy')
      }
    })], 1)] : _c('v-btn', {
      staticClass: "text-transform-none ml-2",
      attrs: {
        "color": "primary",
        "outlined": _vm.$vuetify.theme.dark
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: 'apps',
            query: {
              to: 'app-integrations',
              tab: 'releases'
            }
          });
        }
      }
    }, [_vm._v(" Select ID ")])], 2), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" API Token: "), _vm.selectedToken ? _c('v-chip', {
      staticClass: "black--text cursor-pointer",
      attrs: {
        "color": "grey",
        "label": ""
      },
      on: {
        "click": _vm.getToken
      }
    }, [_c('MaxText', {
      attrs: {
        "text": _vm.selectedToken,
        "max": "15"
      }
    })], 1) : _c('span', {
      staticClass: "semi-bold cursor-pointer primary--text text-decoration-underline bodyFont",
      on: {
        "click": _vm.getToken
      }
    }, [_vm._v(" Select a token ")]), _vm.selectedToken ? _c('v-btn', {
      attrs: {
        "disabled": !_vm.selectedToken,
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.copy(_vm.selectedToken);
        }
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s('mdi-content-copy')
      }
    })], 1) : _vm._e()], 1), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "color": "primary",
        "outlined": _vm.$vuetify.theme.dark
      },
      on: {
        "click": function click($event) {
          return _vm.openDocs(item.linkName);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-message")]), _vm._v(" Documentation ")], 1)], 1)], 1) : _vm._e(), item.value === 'expo-eas' && item !== null && item !== void 0 && item.detail ? _c('v-row', _vm._l(item === null || item === void 0 ? void 0 : item.detail, function (detail, dindex) {
      return _c('v-col', {
        key: dindex,
        attrs: {
          "cols": "12",
          "md": "6"
        }
      }, [_c('v-row', [_c('v-col', {
        staticClass: "pt-5",
        attrs: {
          "cols": "12"
        }
      }, [_c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [detail.img ? _c('v-img', {
        staticClass: "mb-2",
        attrs: {
          "src": detail.img,
          "contain": "",
          "height": "40",
          "width": "40"
        }
      }) : _c('v-icon', {
        staticClass: "mb-1",
        attrs: {
          "color": "primary",
          "size": "40"
        },
        domProps: {
          "textContent": _vm._s(detail.icon)
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('div', [_c('div', [_vm._v(" " + _vm._s(detail.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(detail.description) + " ")])])])], 1)], 1), _c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" App ID: "), _vm.$route.params.app_id ? [_c('v-chip', {
        staticClass: "black--text cursor-pointer",
        attrs: {
          "color": "grey",
          "label": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.$route.params.app_id) + " ")]), _c('v-btn', {
        attrs: {
          "icon": ""
        },
        on: {
          "click": function click($event) {
            return _vm.copy(_vm.$route.params.app_id);
          }
        }
      }, [_c('v-icon', {
        domProps: {
          "textContent": _vm._s('mdi-content-copy')
        }
      })], 1)] : _c('v-btn', {
        staticClass: "text-transform-none ml-2",
        attrs: {
          "color": "primary",
          "outlined": _vm.$vuetify.theme.dark
        },
        on: {
          "click": function click($event) {
            return _vm.$router.push({
              name: 'apps',
              query: {
                to: 'app-integrations',
                tab: 'releases'
              }
            });
          }
        }
      }, [_vm._v(" Select ID ")])], 2), _c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" API Token: "), _vm.selectedToken ? _c('v-chip', {
        staticClass: "black--text cursor-pointer",
        attrs: {
          "color": "grey",
          "label": ""
        },
        on: {
          "click": _vm.getToken
        }
      }, [_c('MaxText', {
        attrs: {
          "text": _vm.selectedToken,
          "max": "15"
        }
      })], 1) : _c('span', {
        staticClass: "semi-bold cursor-pointer primary--text text-decoration-underline bodyFont",
        on: {
          "click": _vm.getToken
        }
      }, [_vm._v(" Select a token ")]), _vm.selectedToken ? _c('v-btn', {
        attrs: {
          "disabled": !_vm.selectedToken,
          "icon": ""
        },
        on: {
          "click": function click($event) {
            return _vm.copy(_vm.selectedToken);
          }
        }
      }, [_c('v-icon', {
        domProps: {
          "textContent": _vm._s('mdi-content-copy')
        }
      })], 1) : _vm._e()], 1), _c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('v-btn', {
        staticClass: "text-transform-none",
        attrs: {
          "color": "primary",
          "outlined": _vm.$vuetify.theme.dark
        },
        on: {
          "click": function click($event) {
            return _vm.openDocs(detail.linkName);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "left": ""
        }
      }, [_vm._v(" mdi-message")]), _vm._v(" Documentation ")], 1)], 1)], 1)], 1);
    }), 1) : _vm._e(), item.value == 'ta-cli' ? _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "my-2 bodyFont"
    }, [_vm._v("Step 1 - Install")]), _c('div', [_c('div', {
      staticClass: "mt-4"
    }, [_vm._v("macOS & Linux")]), _c('div', [_c('div', [_c('v-chip', {
      on: {
        "click": function click($event) {
          return _vm.copy("curl -Ls\n                                      https://github.com/testappio/cli/releases/latest/download/install\n                                      | bash");
        }
      }
    }, [_vm._v(" curl -Ls https://github.com/testappio/cli/releases/latest/download/install | bash ")])], 1)]), _c('div', {
      staticClass: "mt-4 mb-2"
    }, [_c('div', {
      staticClass: "mb-2"
    }, [_vm._v("For windows:")]), _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "color": "primary",
        "outlined": _vm.$vuetify.theme.dark,
        "target": "_blank",
        "href": "https://help.testapp.io/ta-cli/#windows"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-message")]), _vm._v(" Documentation ")], 1)], 1)])]), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "my-2 bodyFont"
    }, [_vm._v("Step 2 - Configure")]), _c('div', {}, [_c('div', {
      staticClass: "my-2"
    }, [_vm._v(" Select a "), _c('span', {
      staticClass: "cursor-pointer primary--text",
      on: {
        "click": _vm.getToken
      }
    }, [_vm._v("token")])]), _c('v-chip', {
      on: {
        "click": _vm.getToken
      }
    }, [_vm._v(" ta-cli config --api_token= "), _c('span', {
      staticClass: "mr-1"
    }, [_vm.selectedToken ? _c('MaxText', {
      attrs: {
        "text": _vm.selectedToken,
        "max": "15"
      }
    }) : _c('span', [_vm._v("get token")])], 1), _vm._v(" --app_id=" + _vm._s(_vm.$route.params.app_id) + " ")]), _c('v-btn', {
      attrs: {
        "disabled": !_vm.selectedToken,
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.copy("ta-cli config --api_token=".concat(_vm.selectedToken, " --app_id=").concat(_vm.$route.params.app_id));
        }
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s('mdi-content-copy')
      }
    })], 1)], 1)]), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', [_c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "color": "primary",
        "outlined": _vm.$vuetify.theme.dark,
        "href": "https://help.testapp.io/ta-cli/",
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-message")]), _vm._v(" Documentation ")], 1)], 1)])], 1) : _vm._e()], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    staticClass: "pb-0 darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('v-icon', {
    attrs: {
      "color": "grey",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-help-circle")]), _c('span', [_vm._v("We missed an integration that you need?")]), _c('span', {
    staticClass: "ml-1 primary--text text-decoration-underline cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.openIntercomChat(_vm.contactMessage);
      }
    }
  }, [_vm._v("Let us know!")])], 1)])] : _vm._e(), !_vm.loading && !_vm.searchServiceList.length && _vm.canManageTeam ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No matching record found"
    }
  })], 1)] : _vm._e()], 2)], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": 900
    },
    on: {
      "close": function close($event) {
        _vm.openSelectTokenModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.openSelectTokenModal ? _c('api-credentials', {
          attrs: {
            "from-app-page": ""
          },
          on: {
            "close": function close($event) {
              _vm.openSelectTokenModal = false;
            },
            "select": _vm.successSelectionToken
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSelectTokenModal,
      callback: function callback($$v) {
        _vm.openSelectTokenModal = $$v;
      },
      expression: "openSelectTokenModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }